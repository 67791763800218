import React from 'react'
import './App.css'
import Calculator from './components/Calculator'

function App() {
  return (

    //Calculator
    <Calculator />
  )
}

export default App